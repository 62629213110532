import React from "react"
import ImgsViewer from "react-images-viewer";

const ImageViewer = ({ images, open, handleClose, currentImg, setCurrentImg }) => {
    const handleNext = () => {
        setCurrentImg(currentImg + 1);
    };

    const handlePrev = () => {
        setCurrentImg(currentImg - 1);
    };

    return <ImgsViewer
        imgs={images}
        currImg={currentImg}
        isOpen={open}
        onClickPrev={handlePrev}
        onClickNext={handleNext}
        onClose={handleClose}
    />
}

export default ImageViewer