import React from "react"
import { Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { FlexRow, VerticalSpacer, Button } from "./"
import contactImage from "../images/contact.jpg"

const Container = styled.div`
    background-size: cover !important;
    background-position-y: 70% !important;
`
const ColorBg = styled.div`
    padding: 6rem 0;
    background-color: rgba(0, 0, 0, 0.84);
    height: 100%;
    @media (max-width: 960px) {
        padding: 4rem 0;
    }
`

const StyledTypography = styled(Typography)`
    && {
        color: #fff;
        font-size: 1.8rem;
        font-weight: 600;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    }
`

const StyledFlexRow = styled(FlexRow)`
    align-items: center;
    justify-content: center;
    && {
        @media (max-width: 960px) {
            flex-direction: column;
            text-align: center;
            padding: 0 0.25rem;
            p {
                margin-bottom: 1rem;
            }
        }
    }
`

const PreFooter = () => {
    let history = useHistory()
    return <Container style={{ background: `url(${contactImage})` }}>
        <ColorBg>
            <StyledFlexRow>
                <StyledTypography variant="body1">Got a project in mind? Let's get started</StyledTypography>
                <VerticalSpacer />
                <Button variant="contained" light onClick={() => history.push("/contact")}>Contact Us</Button>
            </StyledFlexRow>
        </ColorBg>
    </Container>
}

export default PreFooter