import React from "react"
import { Typography, Divider } from "@material-ui/core"
import styled, { css } from "styled-components"
import { FlexColumn, HorizontalSpacer } from "./"
import { systemPalette } from "../utils/constants"

const StyledDivider = styled(Divider)`
    && {
        height: 0.5rem;
        width: 4rem;
        background-color: ${systemPalette.secondary.main};
    }
`

const StyledTypography = styled(Typography)`
    && {
        font-size: 2rem;
        font-weight: 600 !important;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        ${(props) =>
        props.isDarkMode &&
        css`
                color: #fff;
            `
    } 
        @media (max-width: 960px) {
            font-size: 1.3rem;
        } 
    }
`

const PageHeaading = ({ title, centered, subtitle, isDarkMode, isMobile }) => {
    return <FlexColumn alignItemsCenter={centered}>
        <StyledTypography variant="h1" isDarkMode={isDarkMode} style={{ textAlign: centered ? "center" : "left" }}>{title}</StyledTypography>
        {subtitle && <>
            <HorizontalSpacer small />
            <Typography variant={!isMobile ? "h6" : "body1"} color="textSecondary" style={{ fontWeight: "400", textAlign: centered ? "center" : "left" }}>{subtitle}</Typography>
        </>}
        <HorizontalSpacer />
        <StyledDivider />
    </FlexColumn>
}

export default PageHeaading