import React from "react"
import { Link } from "react-router-dom"
import { Typography } from "@material-ui/core"
import styled from "styled-components"
import Layout from "./layout"

const CategoryLink = styled(Typography)`
  && {
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      opacity: 0.5;
    }
    margin-bottom: 0.5rem;
  }
`

const ProjectsCategoriesFilter = ({ data, onClear }) => {
  return (
    <Layout title="Project Categories">
      <div style={{ padding: "0 1.5rem" }}>
        {data?.map((category, index) => (<Link
          to={`/projects/${category._id}`}
          state={{ categoryName: category.name }}
        >
          <CategoryLink onClick={onClear} variant="body1">
            {category.name}
          </CategoryLink>
        </Link>
        ))}
      </div>
    </Layout>
  )
}

export default ProjectsCategoriesFilter
