import fetch from "unfetch"
import { REST_ENDPOINT } from "../utils/constants"

const fireRequest = async ({ params = {}, isPost }) => {
  const headers = {
    "Content-Type": "application/json",
  }

  const requestOptions = {
    method: isPost ? "POST" : "GET",
    cors: "no-cors",
    headers,
    ...(isPost ? { body: JSON.stringify(params) } : {}),
  }

  try {
    const response = await fetch(REST_ENDPOINT, requestOptions)

    if (!response.ok) {
      const error = await response.text()

      return { error: error || "Unknown error occured." }
    }

    return response.json()
  } catch (err) {
    console.error("Something wrong happened", err)
    return err
  }
}

const sendContactMail = async params => {
  try {
    const result = await fireRequest({
      params,
      isPost: true,
    })

    return result
  } catch (err) {
    console.log(err)
  }
}

export { sendContactMail }
