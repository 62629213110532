import { gql } from "@apollo/client"

export const GET_PRODUCTS = gql`
  query getProducts(
    $page: Int!
    $perPage: Int!
    $sorts: String
    $filters: FilterProducts
  ) {
    getProducts(
      page: $page
      perPage: $perPage
      sorts: $sorts
      filters: $filters
    ) {
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        perPage
        pageCount
      }
      items {
        _id
        productName
        productDescription
        categories {
          _id
          name
        }
        tags
        images
        relatedProducts {
          _id
          productName
          productDescription
          categories {
            _id
            name
          }
        }
      }
    }
  }
`

export const GET_PRODUCT = gql`
  query getProduct($_id: String) {
    getProduct(_id: $_id) {
      _id
      productName
      productDescription
      categories {
        _id
        name
      }
      tags
      images
      relatedProducts {
        _id
        productName
        productDescription
        categories {
          _id
          name
        }
        images
      }
    }
  }
`