import React, { useEffect, useState } from "react"
import { Grid, Typography, Breadcrumbs } from "@material-ui/core"
import { useQuery, useLazyQuery } from "@apollo/react-hooks"
import Pagination from "@material-ui/lab/Pagination"
import { Link, useParams } from "react-router-dom"
import styled from "styled-components"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Layout from "../../components/layout"
import { HorizontalSpacer, ProductCard, FlexRow, VerticalSpacer } from "../../components"
import {
    SearchSiteFilter,
    ProjectCategoriesFilter,
} from "../../components/filters"
import { appMaxWidth, systemPalette } from "../../utils/constants"
import { GET_PRODUCTS } from "../../api/products"
import { GET_CATEGORY, GET_CATEGORIES } from "../../api/categories"
import ProjectImage from "../../images/project.jpg"
import { SkeletonProductCard, SkeletonTypography } from "../../components/skeleton"

const HeaderContainer = styled.div`
  padding: 2rem;
  background-color: ${systemPalette.gray.light};
  @media (max-width: 960px) {
        padding: 1rem;
   }
`

const HeaderTitle = styled(Typography)`
  && {
    font-size: 1.8rem;
    font-weight: 500;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    @media (max-width: 960px) {
        font-size: 1rem;
   }
  }
`

const Listings = ({ location }) => {
    let { id } = useParams()
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
    const [categoryName, setCategoryName] = useState("")
    const [page, setPage] = useState(1)
    const [globalLoading, setGlobalLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState("")
    const [
        loadCategory,
        { loading: categoryLoading, data: categoryData },
    ] = useLazyQuery(GET_CATEGORY)
    const { loading: categoriesLoading, data: categories } = useQuery(
        GET_CATEGORIES
    )
    const { loading, data, fetchMore } = useQuery(GET_PRODUCTS, {
        variables: {
            page,
            perPage: 6,
            filters: {
                categories: [id],
            },
        },
        fetchPolicy: "network-only",
        onCompleted: res => {
            setPageCount(res?.getProducts?.pageInfo?.pageCount)
        },
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (!location.state) {
            loadCategory({
                variables: {
                    categoryId: id,
                },
            })
            setCategoryName(categoryData?.getCategory.name)
        } else {
            setCategoryName(location.state.categoryName)
        }
    }, [location, categoryData])

    const handleSearch = e => {
        if (e.key === "Enter") {
            fetchMore({
                variables: {
                    page: 1,
                    perPage: 6,
                    filters: {
                        name: search,
                        categories: [id]
                    },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    setGlobalLoading(false)
                    if (!fetchMoreResult) return prev
                    setPageCount(fetchMoreResult.getProducts.pageInfo.pageCount)
                    return Object.assign({}, prev, {
                        ...fetchMoreResult,
                    })
                },
            })
        }
    }

    const updateData = ({ newSearch }) => {
        setGlobalLoading(true)
        setPage(1)
        setPageCount(0)
        fetchMore({
            variables: {
                page: 1,
                perPage: 6,
                filters: {
                    name: newSearch,
                    categories: [id]
                },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                setGlobalLoading(false)
                if (!fetchMoreResult) return prev
                setPageCount(fetchMoreResult.getProducts.pageInfo.pageCount)
                return Object.assign({}, prev, {
                    ...fetchMoreResult,
                })
            },
        })
    }

    const onClear = () => {
        setSearch("")
        setPage(1)
        window.scrollTo(0, 0)
    }

    return (
        <Layout>
            <HorizontalSpacer height={2} />
            <HeaderContainer>
                <div style={{ maxWidth: appMaxWidth, margin: "auto" }}>
                    <FlexRow alignItemsCenter>
                        {categoryLoading ? <SkeletonTypography /> :
                            <>
                                <Link to="/projects">
                                    <ArrowBackIcon fontSize="small" style={{ verticalAlign: "middle" }} />
                                </Link>
                                <VerticalSpacer extraSmall />
                                <HeaderTitle variant="h3">{categoryName}</HeaderTitle>
                                {isDesktop && <div style={{ marginLeft: "auto" }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link to="/projects">Projects</Link>
                                        <Typography color="textPrimary">{categoryName}</Typography>
                                    </Breadcrumbs>
                                </div>}
                            </>
                        }
                    </FlexRow>
                </div>
            </HeaderContainer>
            <HorizontalSpacer height={isDesktop ? 2 : 1} />
            <div style={{ maxWidth: appMaxWidth, margin: "auto", padding: "0 1rem" }}>
                {!isDesktop ? (
                    <>
                        <SearchSiteFilter
                            onChange={e => setSearch(e.target.value)}
                            value={search}
                            onSubmit={handleSearch}
                            isMobile={!isDesktop}
                        />
                        <HorizontalSpacer />
                    </>
                ) : (
                        <HorizontalSpacer />
                    )}
                <Grid container spacing={isDesktop ? 3 : 2}>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        {!loading && !categoriesLoading && (
                            <Typography variant="body2" color="textSecondary">
                                {`Showing all ${data?.getProducts.count} results`}
                            </Typography>
                        )}
                        <HorizontalSpacer height={isDesktop ? 2 : 1} />
                        <Grid container spacing={2}>
                            {(loading || globalLoading)
                                ? Array.from(new Array(6)).map((data, index) => (
                                    <Grid
                                        item
                                        lg={4}
                                        md={6}
                                        sm={6}
                                        xs={6}
                                        key={index.toString()}
                                    >
                                        <SkeletonProductCard />
                                        <HorizontalSpacer small />
                                    </Grid>
                                ))
                                : data?.getProducts?.items?.map((data, index) => {
                                    return (
                                        <Grid
                                            item
                                            lg={4}
                                            md={6}
                                            sm={6}
                                            xs={6}
                                            key={index.toString()}
                                        >
                                            <Link to={`/${data._id}`}>
                                                <ProductCard
                                                    title={data.productName}
                                                    category={categoryName}
                                                    description={data.productDescription}
                                                    imgUrl={
                                                        (data.images.length > 0 && data.images[0]) ||
                                                        ProjectImage
                                                    }
                                                />
                                            </Link>
                                        </Grid>
                                    )
                                })}
                        </Grid>
                        <HorizontalSpacer large />
                        <FlexRow justifyContentCenter>
                            <Pagination
                                disabled={data?.getProducts.pageInfo.pageCount <= 1 || pageCount <= 1}
                                count={pageCount}
                                page={page}
                                color="primary"
                                size="large"
                                onChange={(e, page) => {
                                    window.scrollTo(0, 0);
                                    setPage(page)
                                    setSearch("")
                                    fetchMore({
                                        variables: {
                                            page,
                                        },
                                        updateQuery: (prev, { fetchMoreResult }) => {
                                            if (!fetchMoreResult) return prev
                                            return Object.assign({}, prev, {
                                                ...fetchMoreResult,
                                            })
                                        },
                                    })
                                }}
                            />
                        </FlexRow>
                    </Grid>
                    {isDesktop && (
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <SearchSiteFilter
                                onChange={e => setSearch(e.target.value)}
                                value={search}
                                onSubmit={handleSearch}
                            />
                            <HorizontalSpacer height={2} />
                            {!categoriesLoading && <ProjectCategoriesFilter
                                data={categories?.getCategories}
                                onClear={onClear}
                                active={id}
                            />}
                        </Grid>
                    )}
                </Grid>
            </div>
            <HorizontalSpacer height={3} />
        </Layout>
    )
}

export default Listings
