import React, { useState } from "react"
import { Typography } from "@material-ui/core"
import styled from "styled-components"
import { FlexColumn, HorizontalSpacer } from "./"

const Image = styled.div`
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-position: center !important;
    background-size: cover !important;
`

const ReadMoreLessBtn = styled(Typography)`
    && {
        color: #61A3F9;
        cursor: pointer;
        transition: 0.5s;
        font-weight: 500;
        &:hover {
            opacity: 0.5;
        }
    }
`

const ServiceCard = ({ imageUrl, title, description, isDesktop }) => {
    const [open, setOpen] = useState(false);
    const [maxChar, setMaxChar] = useState(200)

    return <FlexColumn alignItemsCenter>
        <Image style={{ background: `url(${imageUrl})` }} />
        <HorizontalSpacer small />
        <Typography variant="body1" style={{ fontWeight: "500", fontSize: "1.2rem" }}>{title}</Typography>
        <HorizontalSpacer height={1} />
        <Typography variant={isDesktop ? "body1" : "body2"} style={{ textAlign: "justify", maxWidth: "80%" }}>
            {description.slice(0, maxChar)}
            {description.length > maxChar && !open &&
                <>
                    {"..."}
                    <ReadMoreLessBtn
                        variant={isDesktop ? "body1" : "body2"}
                        component="span"
                        onClick={() => setOpen(!open)}
                    >
                        {" "}read more
                    </ReadMoreLessBtn >
                </>
            }
            {open && description.length > maxChar && description.slice(maxChar, description.length)}
            {description.length > maxChar && open &&
                <>
                    <ReadMoreLessBtn
                        variant={isDesktop ? "body1" : "body2"}
                        component="span"
                        onClick={() => setOpen(!open)}
                    >
                        {" "}read less
                    </ReadMoreLessBtn >
                </>
            }
        </Typography>
    </ FlexColumn>
}

export default ServiceCard