import React from "react";
import { Route, Switch } from "react-router-dom";
import Homepage from "../pages/homepage";
import Categories from "../pages/categories";
import AboutSkai from "../pages/about/skai"
import About4AD from "../pages/about/4ad"
import AboutTeam from "../pages/about/team"
import Projects from "../pages/projects/"
import Project from "../pages/projects/project"
import Contact from "../pages/contact/"

const PageRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact={true} component={Homepage} />
      <Route path="/projects" exact={true} component={Categories} />
      <Route path="/about/skai" exact={true} component={AboutSkai} />
      <Route path="/about/4ad" exact={true} component={About4AD} />
      <Route path="/about/team" exact={true} component={AboutTeam} />
      <Route path="/contact" exact={true} component={Contact} />
      <Route path="/projects/:id" exact={true} component={Projects} />
      <Route path="/:id" exact={true} component={Project} />
    </Switch>
  );
};

export default PageRoutes;
