import React from "react"
import { Link } from "react-router-dom"
import { Divider, Typography, Grid, IconButton } from "@material-ui/core"
import styled from "styled-components"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { VerticalSpacer, FlexRow, FlexColumn, HorizontalSpacer, FeatherIcon, PreFooter } from "./"
import { appMaxWidth } from "../utils/constants";
import { appSettings } from "../data/dummy";

const FooterContainer = styled.div`
    background: #373738;
    padding: 2.5rem 0;
    color: rgba(255, 255, 255, 0.35);
`

const FooterTypographyLogo = styled(Typography)`
    && {
        font-size: 2rem;
        color: rgba(255, 215, 0, 0.60);
        font-family: 'Michroma', sans-serif;
    }
`

const FooterUsefulLinkTitle = styled(Typography)`
    && {
        font-size: 1.2rem;
        color: #fff;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    }
`

const UsefulLinkDivider = styled(Divider)`
    && {
        background: rgba(255, 215, 0, 0.60);
        height: 0.15rem;
        width: 8rem;
    }
`

const StyledIconButton = styled(IconButton)`
    && {
        background: rgba(255, 255, 255, .35);
    }
`

const UsefulLinkContainer = styled.div`
    margin-left: auto;
    @media (max-width: 960px) {
    margin-top: 2rem;
      margin-left: 0;
    }
`

const FooterText = styled.div`
    @media (max-width: 960px) {
        text-align: center;
    }
`

const Footer = ({ withPrefooter }) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    return <>
        {withPrefooter && <PreFooter />}
        <FooterContainer>
            <div style={{ width: "100%", maxWidth: appMaxWidth, margin: "auto", padding: "0 1rem" }}>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FlexColumn>
                            <FooterTypographyLogo variant="body1">{appSettings.name}</FooterTypographyLogo>
                            <HorizontalSpacer />
                            <Typography variant="body2">
                                We will build your visions. We transform your ideas into reality. Think of what you want to achieve. Think SKAI. Consider it done. SKAI is the limit.
                            </Typography>
                            <HorizontalSpacer />
                            <FlexRow alignItemsCenter>
                                <StyledIconButton onClick={() => {
                                    window.location.href = "https://www.facebook.com/Sevilla-Keystone-Architects-SKAI-formerly-AGSAA-157835787612331"
                                }}>
                                    <FeatherIcon icon="facebook" color="rgba(255, 255, 255, .35)" />
                                </StyledIconButton>
                                <VerticalSpacer small />
                                <StyledIconButton onClick={() => {
                                    window.location.href = "https://www.instagram.com/sevillakeystonearchitectsinc/"
                                }}>
                                    <FeatherIcon icon="instagram" color="rgba(255, 255, 255, .35)" />
                                </StyledIconButton>
                                {/* <VerticalSpacer small />
                                <StyledIconButton>
                                    <FeatherIcon icon="youtube" color="rgba(255, 255, 255, .35)" />
                                </StyledIconButton> */}
                                {isDesktop && <>
                                    <VerticalSpacer />
                                    <div style={{ width: "0.1rem", height: "2rem", backgroundColor: "rgba(255, 255, 255, 0.35)" }}></div>
                                    <VerticalSpacer />
                                    <FeatherIcon icon="mail" />
                                    <VerticalSpacer small />
                                    <Typography variant="body2">sevillakeystonearchitects@gmail.com</Typography>
                                </>}
                            </FlexRow>
                            {!isDesktop &&
                                <>
                                    <HorizontalSpacer />
                                    <FlexRow>
                                        <FeatherIcon icon="mail" />
                                        <VerticalSpacer small />
                                        <Typography variant="body2">sevillakeystonearchitects@gmail.com</Typography>
                                    </FlexRow>
                                </>
                            }
                        </FlexColumn>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FlexRow>
                            <UsefulLinkContainer>
                                <FlexRow>
                                    <FlexColumn>
                                        <UsefulLinkDivider />
                                        <HorizontalSpacer small />
                                        <FooterUsefulLinkTitle variant="body1">Company</FooterUsefulLinkTitle>
                                        <HorizontalSpacer />
                                        <Link to="/about/skai">
                                            <Typography variant="body2">SKAI</Typography>
                                        </Link>
                                        <HorizontalSpacer height="1" />
                                        <Link to="/about/4ad">
                                            <Typography variant="body2">4AD</Typography>
                                        </Link>
                                        <HorizontalSpacer height="1" />
                                        <Link to="/about/team">
                                            <Typography variant="body2">TEAM</Typography>
                                        </Link>
                                    </FlexColumn>
                                    <VerticalSpacer large />
                                    {/* <FlexColumn>
                                        <UsefulLinkDivider />
                                        <HorizontalSpacer small />
                                        <FooterUsefulLinkTitle variant="body1">Projects</FooterUsefulLinkTitle>
                                        <HorizontalSpacer />
                                        <Typography variant="body2">Residential</Typography>
                                        <HorizontalSpacer height="1" />
                                        <Typography variant="body2">Medical</Typography>
                                        <HorizontalSpacer height="1" />
                                        <Typography variant="body2">Corporate</Typography>
                                    </FlexColumn> */}
                                    <VerticalSpacer large />
                                    <FlexColumn>
                                        <UsefulLinkDivider />
                                        <HorizontalSpacer small />
                                        <FooterUsefulLinkTitle variant="body1">Contact</FooterUsefulLinkTitle>
                                        <HorizontalSpacer />
                                        <Typography variant="body2">Tel: (02) 8832-5561</Typography>
                                        <HorizontalSpacer height="1" />
                                        <Typography variant="body2">Fax: (02) 8831-9723</Typography>
                                        <HorizontalSpacer height="1" />
                                        {/* <Typography variant="body2">Location</Typography> */}
                                    </FlexColumn>
                                </FlexRow>
                            </UsefulLinkContainer>
                        </FlexRow>
                    </Grid>
                </Grid>
                <HorizontalSpacer height="4" />
                <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.35)" }} />
                <HorizontalSpacer />
                <FooterText>
                    <Typography variant="body2">
                        © Copyright 2020 SKAI Sevilla Keystone Architects Inc. All Rights Reserved
                    </Typography>
                </FooterText>
            </div>
        </FooterContainer>
    </>
}

export default Footer