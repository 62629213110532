import React from "react"
import { Typography } from "@material-ui/core"
import styled, { css } from "styled-components"
import { HorizontalSpacer } from "./"

const ProductImage = styled.div`
    height: 400px;
    background-size: cover !important;
    background-color: #373738;
    background-position: center !important;
    border-radius: 0.8rem;
    ${(props) =>
        props.isPortrait &&
        css`
               height: 100%;
            `
    }
    @media (max-width: 960px) {
        ${(props) =>
        props.isPortrait &&
        css`
                height: 600px !important;
        `
    }
    } 
`

const Subtitle = styled(Typography)`
    && {
        color: #C3B383;
        text-align: center;
        font-style: italic;
    }
`

const AboutImage = ({ imgUrl, subtitle, isPortrait }) => {
    return <>
        {isPortrait ? <img src={imgUrl} alt="img" /> : <ProductImage style={{ background: `url(${imgUrl})` }} />}
        {subtitle && <>
            <HorizontalSpacer small />
            <Subtitle variant="body1">"{subtitle}"</Subtitle>
        </>}
    </>
}

export default AboutImage