import React, { useEffect } from "react"
import { Typography, Grid } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Layout from "../../components/layout"
import { HorizontalSpacer, AboutNav, AboutHeader } from "../../components"
import { appMaxWidth } from "../../utils/constants"
import BannerImage from "../../images/skai-group.jpg"
import TeamImage from "../../images/team.jpg"

const AboutSkai = () => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return <Layout withPrefooter>
        <HorizontalSpacer height={1} />
        <AboutNav value={0} imageUrl={BannerImage} />
        <div style={{ maxWidth: appMaxWidth, margin: "auto", padding: "0 1rem" }}>
            <AboutHeader title="Sevilla Keystone Architects Inc" />
            <HorizontalSpacer height={isDesktop ? 3 : 1} />
            <Grid container spacing={8}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        SEVILLA KEYSTONE ARCHITECTS INC. (formerly AG Sevilla + Associates Architects) founded in August 1995 started as a multi-faceted ARCHITECTURAL DESIGN, INTERIOR DESIGN and GENERAL CONSTRUCTION company with a bulwark of highly professional and experienced technical men and women offering complete design and construction services concurring versatile architecture apt for the milieu of residential, industrial, institutional, commercial and recreational structures.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        The firm is known for its prolific experience in the world of design and construction and has established its name time-honored with professionalism, efficiency, quality of workmanship and the highest form of service. All these years, the company maintained stable operations due mainly to the potent tenacity of the staff and management team under the principal architect.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        The team considers each project as their own and gives their personal supervision to achieve maximum quality of works and consistency from its people to deliver incomparable excellent service.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        The vast accomplishments of SKAI clearly seen in all its completed projects, satisfied clients and numerous referrals streamlined the company to a sturdy progress that speaks of its excellent reputation.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        The growth of SKAI is undeniable that it created 4:AD Architecture • Design Inc. to handle all design projects for the limitless possibilities and projects of the architectural profession.
                    </Typography>
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        The company and its people give service with their utmost capability to give back the glory and praise to The Most High.
                    </Typography>
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        SEVILLA KEYSTONE ARCHITECTS INC. creates a friendly, trustworthy, professional and a communication-centered relationship with its clients through veritable architecture in epoch of a new decade.
                    </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <img src={TeamImage} alt="skai" />
                </Grid>
            </Grid>
        </div>
        <HorizontalSpacer height={3} />
    </Layout>
}

export default AboutSkai