import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import PageRoutes from "./routes/navigation";
import client from "./utils/connection";
import "./index.css";
import { Header } from "./components/";
import { systemPalette } from "./utils/constants"
// import Footer from "./components/footer";
// import * as serviceWorker from "./serviceWorker";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#373738",
      contrastText: "#ffffff",
    },
    secondary: {
      main: systemPalette.secondary.main,
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiBadge: {
      badge: {
        backgroundColor: "#ff3232",
        color: "#ffffff",
      },
    },
    MuiRating: {
      iconEmpty: {
        color: "#fff !important",
      },
    },
    MuiButton: {
      root: {
        textTransform: "inherit",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <Header />
            <PageRoutes />
            {/* <Footer /> */}
          </BrowserRouter>
        </SnackbarProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
