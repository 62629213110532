import React, { useState } from "react"
import { AppBar, Toolbar, Typography, Button, Drawer, IconButton } from "@material-ui/core/"
import styled, { css } from "styled-components"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import MenuIcon from "@material-ui/icons/Menu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { FlexRow, VerticalSpacer, FlexColumn } from "./"
import { appMaxWidth } from "../utils/constants"
import { appSettings } from "../data/dummy"
import SKAILogo from "../images/skaiicon.png"

const HeaderLink = styled(Button)`
  && {
    ${props =>
    props.isActive &&
    css`
        span:first-of-type {
          border-bottom: solid 2px rgba(255, 215, 0, 0.6);
        }
      `}
  }
`

const Header = () => {
  const location = useLocation()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false)

  const renderRoutes = () => {
    return (
      <>
        <Link to="/">
          <HeaderLink
            color="inherit"
            isActive={location.pathname === "/"}
            onClick={() => setOpen(false)}
          >
            Home
          </HeaderLink>
        </Link>
        <VerticalSpacer />
        <Link to="/about/skai">
          <HeaderLink
            color="inherit"
            isActive={
              location.pathname === "/about/skai" ||
              location.pathname === "/about/4ad" ||
              location.pathname === "/about/team"
            }
            onClick={() => setOpen(false)}
          >
            About
                </HeaderLink>
        </Link>
        <VerticalSpacer />
        <Link to="/projects">
          <HeaderLink
            color="inherit"
            isActive={
              location.pathname === "/projects" ||
              location.pathname === "/projects/listings" ||
              location.pathname === "/project"
            }
            onClick={() => setOpen(false)}
          >
            Projects
                </HeaderLink>
        </Link>
        <VerticalSpacer />
        <Link to="/contact">
          <HeaderLink
            color="inherit"
            isActive={location.pathname === "/contact"}
            onClick={() => setOpen(false)}
          >
            Contact
          </HeaderLink>
        </Link>
      </>
    )
  }

  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar style={{ width: "100%", margin: "auto", maxWidth: appMaxWidth }}>
        <FlexRow alignItemsCenter>
          {!isDesktop && (
            <>
              <IconButton size="small" onClick={() => setOpen(true)}>
                <MenuIcon style={{ color: "#fff" }} />
              </IconButton>
              <VerticalSpacer small />
            </>
          )}
          <Link to="/">
            {/* <img src={SKAILogo} alt="logo" style={{ height: "40px" }} /> */}
            <Typography
              variant="h6"
              style={{
                color: "rgba(255, 215, 0, 0.60)",
                fontFamily: "'Michroma', sans-serif",
              }}
            >
              {appSettings.name}
            </Typography>
          </Link>
          {isDesktop ? <div style={{ marginLeft: "auto" }}>
            <FlexRow>
              {renderRoutes()}
            </FlexRow>
          </div> : <Drawer open={open} onClose={() => setOpen(false)}>
              <FlexColumn style={{ width: "200px", padding: "20px" }}>
                {renderRoutes()}
              </FlexColumn>
            </Drawer>}
        </FlexRow>
      </Toolbar>
    </AppBar>
  )
}

export default Header
