import React from "react"
import { Typography } from "@material-ui/core"
import styled from "styled-components"
import { FlexColumn } from "./"

const Container = styled.div`
    background-size: cover !important;
    background-position-y: 70% !important;
    height: 16rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        opacity: 0.8;
    }
    @media (max-width: 960px) {
        height: 12rem;
    }
`
const ColorBg = styled.div`
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    > div {
        height: 100%;
    }
    padding: 0.2rem;
`

const StyledTypography = styled(Typography)`
    && {
        color: #fff;
        font-size: 1.8rem;
        font-weight: 600;
        text-align: center;
        @media (max-width: 960px) {
            font-size: 1rem;
        }
    }
`

const CategoryCard = ({ imageUrl, title }) => {
    return <Container style={{ background: `url(${imageUrl})` }}>
        <ColorBg>
            <FlexColumn alignItemsCenter justifyContentCenter>
                <StyledTypography variant="body1">{title}</StyledTypography>
            </FlexColumn>
        </ColorBg>
    </Container>
}

export default CategoryCard