import React, { useEffect } from "react"
import { Typography, Grid } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Layout from "../../components/layout"
import { HorizontalSpacer, AboutNav, AboutHeader, AboutImage } from "../../components"
import { appMaxWidth } from "../../utils/constants"
import AntonioImage from "../../images/antonio.jpg"

const AboutTeam = () => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return <Layout withPrefooter>
        <HorizontalSpacer height={1} />
        <AboutNav value={2} />
        <div style={{ maxWidth: appMaxWidth, margin: "auto", padding: "0 1rem" }}>
            <AboutHeader title="ANTONIO G. SEVILLA JR." />
            <HorizontalSpacer height={isDesktop ? 3 : 1} />
            <Grid container spacing={8}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        The president and principal architect of Sevilla Keystone Architects, Inc. (SKAI) and 4: Architecture •
                        Design (4: AD) started working while he was in his college years. The regular “On the Job” training
                        became a steady job when his boss architect discovered his natural talent in design and project
                        management. He was among the first batch of Architecture students at Pamantasan ng Lungsod ng
                        Maynila and among the lucky ten students who first graduated after five years.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        Ar. Sevilla’s extensive career experience was through years of learning from a number of architectural
                        firms to envelope and digest various styles and practices from building design both in horizontal and
                        vertical structures and interior design by joining the top-notch local and international companies.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        His professional experience and training in Singapore sealed his decision to start his own design and
                        build office in 1995.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        He often amuses the office staff by telling anecdotes from his early college years and how he started his
                        company. Ar. Ate’ feels pride that he was among those who experienced taking the Architecture Board
                        Exam having to draw details in the subjects of History and Professional Practice.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        A hiker, food lover, war movies fan, sleep-deprived individual, roller-coaster screamer, a struggling
                        romantic and a funny dad, Ar. Antonio G. Sevilla wishes to see the transformation of the architecture we
                        have now to the mind blowing ultra-modern space-age era kind.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        Ate’ (which evolved from Ati’) being his nickname derived from the festive ati-atihan, is a certified dog
                        lover who pets even the stray scruffy ones. He dreams of having a big lot not for a house but for his
                        dream of owning a St. Bernard dog.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        The youngest from the brood of three boys and a son to an authentic full blooded Chinese father and a
                        Filipina mother, Ar. Ate’ realized from an early age that he would have his own company to run after
                        completing his college education. His founding years at Philippine Pasay Chung Hua Academy enriched
                        his business abilities to handle the company.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        When asked what he would do when he retires, he answers in a heartbeat, “I’ll play computer games.”
                    </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <AboutImage isPortrait imgUrl={AntonioImage} subtitle="I knew from an early age that I'll have my own company to run." />
                </Grid>
            </Grid>
        </div>
        <HorizontalSpacer height={6} />
    </Layout>
}

export default AboutTeam