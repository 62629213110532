import React from "react"
import styled, { css } from "styled-components"
import { Typography, Divider } from "@material-ui/core"
import { HorizontalSpacer } from "./"
import { systemPalette } from "../utils/constants"
import { SkeletonTypography } from "./skeleton"

const HeaderTitle = styled(Typography)`
    && {
        font-size: 1.8rem;
        font-weight: 600;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        @media (max-width: 960px) {
            font-size: 1.2rem;
        }
    }
`
const StyledDivider = styled(Divider)`
    && {
        height: 0.2rem;
        background-color: ${systemPalette.gray.light};
        ${(props) =>
        props.isDarkMode &&
        css`
                background-color: #3C3C3D;
            `
    }  
    }
`

const Subtitle = styled(Typography)`
    && {
        color: #C3B383;
        text-align: center;
    }
`

const AboutHeader = ({ title, subtitle, isDarkMode, loading }) => {

    return <>
        {loading ? <SkeletonTypography width="50%" /> : <HeaderTitle variant="h3">{title}</HeaderTitle>}
        <HorizontalSpacer />
        <StyledDivider isDarkMode={isDarkMode} />
        {subtitle && !loading && <>
            <HorizontalSpacer />
            <Subtitle variant="body1">"{subtitle}"</Subtitle>
        </>}
    </>
}

export default AboutHeader