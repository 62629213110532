import styled, { css } from "styled-components";

const HorizontalSpacer = styled.div`
  height: 1.5rem;
  
  ${(props) =>
    props.extraSmall &&
    css`
      height: 0.3rem;
    `}
  ${(props) =>
    props.small &&
    css`
      height: 0.5rem;
    `}
  ${(props) =>
    props.large &&
    css`
      height: 2rem;
    `}
    ${(props) =>
    props.height &&
    css`
      height: ${props.height}rem;
    `}
`;

export default HorizontalSpacer;
