import { gql } from "@apollo/client"

export const GET_CATEGORIES = gql`
  {
    getCategories {
      _id
      name
      categoryImageUrl
    }
  }
`

export const GET_CATEGORY = gql`
  query getCategory($categoryId:ID!){
    getCategory(categoryId:$categoryId){
      _id
      name
      categoryImageUrl
    }
  }
`
