import React, { useState } from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { FlexColumn, FlexRow, HorizontalSpacer, VerticalSpacer, ImageViewer } from "./"
import { CommonSkeleton } from "./skeleton"

const ImageThumbnail = styled.div`
    border-radius: 0.5rem;
    width: 100%;
    background-color: gray;
    background-size: cover !important;
    background-position: center !important;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        opacity: 0.5;
    }
`

const MainPhoto = styled(ImageThumbnail)`
    height: 24rem;
    @media (max-width: 960px) {
        height: 16rem;
    }
`

const SmallPhoto = styled(ImageThumbnail)`
    height: 11.5rem;
    @media (max-width: 960px) {
        height: 7.5rem;
    }
`

const ImagesContainer = styled.div`
    width: 100%;
`

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    border-radius: 0.5rem;
`

const OverlayText = styled(Typography)`
    color: #fff;
    font-size: 2rem !important;
    font-weight: 500;
    @media (max-width: 960px) {
        font-size: 1.5rem !important;
    }

`

const formatImgUrl = (imgUrl) => {
    if (/\s/.test(imgUrl)) {
        return encodeURI(imgUrl)
    }
    return imgUrl
}

const Gallery = ({ images, loading }) => {
    const [open, setOpen] = useState(false);
    const [currentImg, setCurrentImg] = useState(0);

    const handleOpen = (index) => {
        setCurrentImg(index)
        setOpen(true)
    }

    return <>
        {!loading &&
            <ImageViewer
                images={images.map(data => ({ src: data }))}
                open={open}
                handleClose={() => {
                    setOpen(false)
                    setCurrentImg(0)
                }}
                currentImg={currentImg} setCurrentImg={setCurrentImg}
            />
        }
        <FlexRow>
            {loading ? <CommonSkeleton height="24rem" /> : <MainPhoto style={{ background: `url(${formatImgUrl(images[0])})` }} onClick={() => handleOpen(0)} />}
            {images?.length > 2 && <>
                <VerticalSpacer />
                <ImagesContainer>
                    <FlexRow>
                        <FlexColumn>
                            {loading ? <CommonSkeleton height="11.5rem" /> : <SmallPhoto style={{ background: `url(${formatImgUrl(images[1])})` }} onClick={() => handleOpen(1)} />}
                            <HorizontalSpacer height="1" />
                            {loading ? <CommonSkeleton height="11.5rem" /> : <SmallPhoto style={{ background: `url(${formatImgUrl(images[2])})` }} onClick={() => handleOpen(2)} />}
                        </FlexColumn>
                        {images?.length > 3 && <>
                            <VerticalSpacer />
                            <FlexColumn>
                                {loading ? <CommonSkeleton height="11.5rem" /> : <SmallPhoto style={{ background: `url(${formatImgUrl(images[3])})` }} onClick={() => handleOpen(3)} />}
                                <HorizontalSpacer height="1" />
                                {loading ? <CommonSkeleton height="11.5rem" /> :
                                    <SmallPhoto style={{ background: `url(${formatImgUrl(images[4])})` }} onClick={() => handleOpen(4)}>
                                        {images?.length > 6 && <Overlay>
                                            <FlexColumn alignItemsCenter justifyContentCenter style={{ height: "100%" }}>
                                                <OverlayText variant="body1">{images?.length - 5}+</OverlayText>
                                            </FlexColumn>
                                        </Overlay>}
                                    </SmallPhoto>}
                            </FlexColumn>
                        </>}
                    </FlexRow>
                </ImagesContainer>
            </>}
            {images?.length === 2 && <>
                <VerticalSpacer />
                {loading ? <CommonSkeleton height="24rem" /> : <MainPhoto style={{ background: `url(${images[1]})` }} onClick={() => handleOpen(1)} />}
            </>}
        </FlexRow>
    </>
}

export default Gallery