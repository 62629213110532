import React, { useEffect } from "react"
import { Grid } from "@material-ui/core"
import { Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { PageHeading, HorizontalSpacer, CategoryCard } from "../../components"
import { appMaxWidth } from "../../utils/constants"
import { GET_CATEGORIES } from "../../api/categories"
import { SkeletonProductCard } from "../../components/skeleton";
import Layout from "../../components/layout"

const Projects = () => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const { loading, data } = useQuery(GET_CATEGORIES, {
        notifyOnNetworkStatusChange: true,
        onCompleted: res => {
            console.log(res)
        },
        onError: err => {
            console.log(err)
        }
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Layout>
            <HorizontalSpacer height={isDesktop ? 6 : 4} />
            <PageHeading title="Categories" centered />
            <HorizontalSpacer height={3} />
            <div style={{ maxWidth: appMaxWidth, margin: "auto", padding: "0 1rem" }}>
                <Grid container spacing={isDesktop ? 3 : 2}>
                    {loading ? Array.from(new Array(6)).map((data, index) => {
                        return (
                            <Grid
                                item
                                lg={4} md={4} sm={6} xs={6}
                                key={index.toString()}
                            >
                                <SkeletonProductCard />
                            </Grid>
                        );
                    }) : data?.getCategories?.map((category, index) => {
                        return (
                            <Grid item lg={4} md={4} sm={6} xs={6} key={index.toString()}>
                                <Link
                                    to={`/projects/${category._id}`}
                                    state={{ categoryName: category.name }}
                                >
                                    <CategoryCard
                                        imageUrl={category.categoryImageUrl}
                                        title={category.name}
                                    />
                                </Link>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
            <HorizontalSpacer height={isDesktop ? 6 : 4} />
        </Layout>
    )
}

export default Projects
