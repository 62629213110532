import React, { useEffect } from "react"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import { useQuery } from "@apollo/react-hooks"
import { Link } from "react-router-dom"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import Team from "../../components/team"
import {
    PageHeading,
    HorizontalSpacer,
    ServiceCard,
    Slider,
    ProductCard,
    Testimonial,
} from "../../components"
import { appMaxWidth, systemPalette } from "../../utils/constants"
import { services, testimonials } from "../../data/dummy"
import { GET_PRODUCTS } from "../../api/products"
import { SkeletonProductCard } from "../../components/skeleton"

const FeaturedContainer = styled.div`
  padding: 6rem 0;
  background-color: ${systemPalette.gray.light};
  @media (max-width: 960px) {
    padding: 4rem 0 3rem 0;
  }
`
const IndexPage = () => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
    const { loading, data } = useQuery(GET_PRODUCTS, {
        variables: {
            page: 1,
            perPage: 12,
            filters: {
                isFeatured: true,
            },
        },
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Layout withPrefooter>
            <Hero isDesktop={isDesktop} />
            <PageHeading title="Our Most Popular Services" centered />
            <HorizontalSpacer height={3} />
            <div style={{ maxWidth: appMaxWidth, margin: "auto", padding: "0 0.5rem" }}>
                <Grid container spacing={2} justify="center">
                    {services.map((service, index) => {
                        return (
                            <Grid item lg={4} md={4} sm={6} xs={12} key={index.toString()}>
                                <ServiceCard
                                    imageUrl={service.imageUrl}
                                    title={service.title}
                                    description={service.description}
                                    isDesktop={isDesktop}
                                />
                                <HorizontalSpacer />
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
            <HorizontalSpacer height={isDesktop ? 6 : 3} />
            <FeaturedContainer>
                <div
                    style={{ maxWidth: appMaxWidth, margin: isDesktop ? "auto" : "0 1rem", padding: "0 1rem" }}
                >
                    <PageHeading title="Featured Projects" centered />
                    <HorizontalSpacer height={2} />
                    {loading ? <Grid container spacing={2}>
                        {loading &&
                            Array.from(new Array(isDesktop ? 4 : 3)).map((data, index) => {
                                return (
                                    <Grid item lg={3} md={4} sm={6} xs={12} key={index.toString()}>
                                        <SkeletonProductCard />
                                    </Grid>
                                );
                            })}
                    </Grid> : <Slider isDesktop={isDesktop} slidesToShow={!isDesktop ? 1 : ""}>
                            {data?.getProducts?.items?.map((data, index) => {
                                return (
                                    <div key={index.toString()}>
                                        <Link to={`/${data._id}`}>
                                            <ProductCard
                                                isTransparent
                                                title={data?.productName}
                                                category={data?.categories[0].name}
                                                description={data?.productDescription}
                                                imgUrl={data?.images[0]}
                                            />
                                        </Link>
                                    </div>
                                )
                            })}
                        </Slider>}
                </div>
            </FeaturedContainer>
            <Team isDesktop={isDesktop} />
            <HorizontalSpacer height={isDesktop ? 6 : 3} />
            <div style={{ maxWidth: appMaxWidth, margin: isDesktop ? "auto" : "0 1rem", padding: "0 1rem" }}>
                <PageHeading
                    title="What People Say About Us"
                    subtitle="Client Testimonials"
                    centered
                    isMobile={!isDesktop}
                />
                <HorizontalSpacer height="2" />
                <div style={{ textAlign: "center", maxWidth: "1024px", margin: "auto" }}>
                    <Slider slidesToShow={1} dots>
                        {testimonials.map((data, index) => (
                            <Testimonial
                                isDesktop={isDesktop}
                                key={index.toString()}
                                data={data}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
            <HorizontalSpacer height={isDesktop ? 6 : 4} />
        </Layout>
    )
}

export default IndexPage
