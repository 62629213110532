import React from "react";
import { Typography } from "@material-ui/core/";
import styled from "styled-components";
import { HorizontalSpacer, FlexRow } from "../";
import { systemPalette } from "../../utils/constants"

const FilterTitleContainer = styled(FlexRow)`
  && {
    padding: 1rem 1.5rem;
    background-color: ${systemPalette.gray.light};
    border-radius: 0.25rem;
  }
`;

const FilterLayout = props => {
    const { title, children, isMobile } = props;
    return (
        <>
            {!isMobile && <FilterTitleContainer alignedCenter>
                <Typography style={{ fontWeight: "500" }} variant="body1">{title}</Typography>
            </FilterTitleContainer>}
            <>
                {!isMobile && <HorizontalSpacer />}
                {children}
            </>
        </>
    );
};

export default FilterLayout;
