import React, { useEffect } from "react"
import { Typography, Grid } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Layout from "../../components/layout"
import { HorizontalSpacer, AboutNav, AboutHeader } from "../../components"
import { appMaxWidth } from "../../utils/constants"
import ArchitectureImage from "../../images/4AD-group.jpg"

const About4AD = () => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return <Layout withPrefooter>
        <HorizontalSpacer height={1} />
        <AboutNav value={1} />
        <div style={{ maxWidth: appMaxWidth, margin: "auto", padding: "0 1rem" }}>
            <AboutHeader title="4:AD" />
            <HorizontalSpacer height={isDesktop ? 3 : 1} />
            <Grid container spacing={8}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        It all started with a line. A straight line. It curved into a vision. A vision to create design. An extraordinary design.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        4: Architecture • Design is a Philippine based company created by three professional architects with the same vision to connect with people through effective cutting-edge-sophisticated design within the archipelago and the global market. Architects Olivia M. Lauron, Jill Rochelle M. Sevilla and Antonio G. Sevilla, Jr. are the founding partners in creating limitless and human-centered designs that are tailor fit for every client.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        4:AD stands for the 4 dimensions that the team takes into account in every design: The Site. The Building Plan. The Building Exterior. The Building Interior.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        The designamic team always manifests unshakable optimism to move beyond the projected purposeful change where something did not exist before. They will be trend-setters who will always be imagining the future that is timeless.
                    </Typography>
                    <HorizontalSpacer />
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        Their designs will work with a fresh vocabulary of ideas putting human experiences at the center of their design while having soul to contribute to the discipline of architecture and living.
                    </Typography>
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        4:AD specializes in residential, hospitality, healthcare, academic, cultural, corporate, entertainment and industrial design. An extensive spectrum of expertise enables 4:AD to approach effective planning through research and design investigation in creating spaces. The team creates honest, ethical, sustainable, modern and transparent approach to design solutions established from years of experience. Architecture will be done better.
                    </Typography>
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        “Our style transcends to enhance our sense of presence, identity and relationship with people and the physical space we inhabit. Every client will be handled with the same care and attention to purpose while delivering inimitable solutions to individual needs.”
                    </Typography>
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        "We are 4:AD. We create architecture, landscapes, interiors and brand design. We transform ordinary to something that is extraordinary. We make design breathe and give life into it. We archi-nected."
                    </Typography>
                    <Typography variant={isDesktop ? "body1" : "body2"} component="p">
                        “Think Design. Think of what you want to achieve. Think 4:AD. Consider it done.”
                    </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <img src={ArchitectureImage} alt="4ad" />
                </Grid>
            </Grid>
        </div>
        <HorizontalSpacer height={3} />
    </Layout>
}

export default About4AD