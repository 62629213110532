export const API_URL = process.env.REACT_APP_API_URL;
export const APP_ID = process.env.REACT_APP_APP_ID;
export const REST_ENDPOINT = process.env.REACT_APP_REST_ENDPOINT;

export const systemPalette = {
    primary: {
        main: "#373738",
    },
    secondary: {
        main: "#61A3F9",
        light: "#E4F2FE",
    },
    gray: {
        light: "#efefef",
        dark: "#F3F3F3",
    },
}

export const appMaxWidth = "1368px"