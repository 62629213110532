import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export const SkeletonTextField = () => {
  return <Skeleton variant="rect" height={40} width="100%" />;
};

export const SkeletonProductCard = () => {
  return <Skeleton variant="rect" height={270} />;
};

export const SkeletonSelect = () => {
  return <Skeleton variant="rect" width="100%" height={40} />;
};

export const SkeletonTypography = ({ width, height }) => {
  return (
    <Skeleton
      variant="rect"
      width={width ? width : 200}
      height={height ? height : 40}
    />
  );
};

export const CommonSkeleton = ({ width, height }) => {
  return (
    <Skeleton
      variant="rect"
      width={width ? width : "100%"}
      height={height ? height : 40}
    />
  );
};
