import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import PrintIcon from "@material-ui/icons/Print";
import styled from "styled-components";
import validator from "validator";
import { useSnackbar } from "notistack";

import Layout from "../../components/layout";
import {
  HorizontalSpacer,
  Button,
  FeatherIcon,
  FlexRow,
  VerticalSpacer,
} from "../../components";
import { appMaxWidth, systemPalette } from "../../utils/constants";
import BannerImage from "../../images/map.png";
import { sendContactMail } from "../../api/rest";

const Banner = styled.div`
  height: 360px;
  background-position: center !important;
  @media (max-width: 960px) {
    height: 240px;
  }
`;

const HeaderTitle = styled(Typography)`
  && {
    font-size: 1.8rem;
    font-weight: 600;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }
`;

const DarkBackground = styled.div`
  padding: 2rem;
  background-color: ${systemPalette.primary.main};
  color: #fff;
  border-radius: 0.2rem;
`;

const StyledIconButton = styled(IconButton)`
  && {
    background: rgba(255, 255, 255, 0.35);
  }
`;

const ContactEmailTemplate = (fname, lname, mobile, email, message) => {
  return `
    <body>
     <div><p>Name: ${fname} ${lname}</p></div>
      <div><p>Email: ${email}</p></div>
      ${mobile && `<div><p>Mobile: ${mobile}</p></div>`}
       <div><p>Message: ${message}</p></div>
    </body>
  `;
};

const Contact = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    message: "",
    email: "",
    mobile: "",
  });
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const [select, setSelect] = useState("");

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { fname, lname, message, email, mobile } = values;
      setLoading(true);
      await sendContactMail({
        toEmails: [email],
        subject: select,
        message: ContactEmailTemplate(fname, lname, mobile, message),
      });
      setLoading(false);
      enqueueSnackbar("Inquiry sent!", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    } catch (err) {
      enqueueSnackbar("Oh no, Something went wrong!", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  };

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    if (id === "email") {
      !validator.isEmail(value)
        ? setEmailError("Invalid email address")
        : setEmailError("");
    }
    setValues({ ...values, [id]: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <HorizontalSpacer height={2} />
      <Banner style={{ background: `url(${BannerImage})` }} />
      <HorizontalSpacer height={3} />
      <div style={{ maxWidth: appMaxWidth, margin: "auto", padding: "0 1rem" }}>
        <Grid container spacing={6}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <form onSubmit={handleSubmit}>
              <HeaderTitle variant="h5">Send us a message</HeaderTitle>
              <Typography variant="body1">
                Please fill out all required fields*
              </Typography>
              <HorizontalSpacer height={2} />
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <TextField
                    fullWidth
                    id="fname"
                    label="First Name"
                    variant="outlined"
                    disabled={loading}
                    value={values.fname}
                    onChange={onHandleChange}
                    required
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <TextField
                    fullWidth
                    id="lname"
                    label="Last Name"
                    variant="outlined"
                    disabled={loading}
                    value={values.lname}
                    onChange={onHandleChange}
                    required
                  />
                </Grid>
              </Grid>
              <HorizontalSpacer />
              <TextField
                fullWidth
                id="email"
                label="Email"
                variant="outlined"
                disabled={loading}
                value={values.email}
                error={!!emailError}
                helperText={emailError}
                onChange={onHandleChange}
                required
              />
              <HorizontalSpacer />
              <TextField
                fullWidth
                id="mobile"
                label="Mobile (optional)"
                variant="outlined"
                disabled={loading}
                value={values.mobile}
                onChange={onHandleChange}
              />
              <HorizontalSpacer />
              <FormControl disabled={loading} fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">
                  Select Subject
                </InputLabel>
                <Select
                  labelId="subject-label"
                  id="subject-select"
                  value={select}
                  onChange={(e) => setSelect(e.target.value)}
                >
                  <MenuItem value="I would like to inquire for Design">
                    I would like to inquire for Design
                  </MenuItem>
                  <MenuItem value="I would like to inquire for Construction">
                    I would like to inquire for Construction
                  </MenuItem>
                  <MenuItem value="Hello">Hello</MenuItem>
                </Select>
              </FormControl>
              <HorizontalSpacer />
              <TextField
                fullWidth
                id="message"
                label="Message"
                disabled={loading}
                variant="outlined"
                multiline
                rows={4}
                value={values.message}
                onChange={onHandleChange}
                required
              />
              <HorizontalSpacer />
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                color="secondary"
                endIcon={<SendIcon />}
              >
                {loading ? "Sending..." : "Send"}
              </Button>
            </form>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <DarkBackground>
              <Grid container>
                <Grid item lg={2} md={3} sm={2} xs={2}>
                  <LocationOnIcon
                    style={{ color: "rgba(255, 215, 0, 0.60)" }}
                  />
                </Grid>
                <Grid item lg={10} md={9} sm={10} xs={10}>
                  <Typography variant="body2">
                    2570-C Taft Avenue Ext., Baranggay 93, Zone 11 San Rafael
                    District , Pasay City, Metro Manila, 1300
                  </Typography>
                </Grid>
              </Grid>
              <HorizontalSpacer />
              <Grid container>
                <Grid item lg={2} md={3} sm={2} xs={2}>
                  <EmailIcon style={{ color: "rgba(255, 215, 0, 0.60)" }} />
                </Grid>
                <Grid item lg={10} md={9} sm={10} xs={10}>
                  <Typography variant="body2">
                    sevillakeystonearchitects@gmail.com
                  </Typography>
                </Grid>
              </Grid>
              <HorizontalSpacer />
              <Grid container>
                <Grid item lg={2} md={3} sm={2} xs={2}>
                  <PhoneIcon style={{ color: "rgba(255, 215, 0, 0.60)" }} />
                </Grid>
                <Grid item lg={10} md={9} sm={10} xs={10}>
                  <Typography variant="body2">(02) 8832-5561</Typography>
                </Grid>
              </Grid>
              <HorizontalSpacer />
              <Grid container>
                <Grid item lg={2} md={3} sm={2} xs={2}>
                  <PrintIcon style={{ color: "rgba(255, 215, 0, 0.60)" }} />
                </Grid>
                <Grid item lg={10} md={9} sm={10} xs={10}>
                  <Typography variant="body2">(02) 8831-9723</Typography>
                </Grid>
              </Grid>
              <HorizontalSpacer large />
              <FlexRow alignItemsCenter>
                <StyledIconButton
                  onClick={() => {
                    window.location.href =
                      "https://www.facebook.com/Sevilla-Keystone-Architects-SKAI-formerly-AGSAA-157835787612331";
                  }}
                >
                  <FeatherIcon
                    icon="facebook"
                    color="rgba(255, 255, 255, .35)"
                  />
                </StyledIconButton>
                <VerticalSpacer small />
                <StyledIconButton
                  onClick={() => {
                    window.location.href =
                      "https://www.instagram.com/sevillakeystonearchitectsinc/";
                  }}
                >
                  <FeatherIcon
                    icon="instagram"
                    color="rgba(255, 255, 255, .35)"
                  />
                </StyledIconButton>
                {/* <VerticalSpacer small />
                                <StyledIconButton>
                                    <FeatherIcon
                                        icon="youtube"
                                        color="rgba(255, 255, 255, .35)"
                                    />
                                </StyledIconButton> */}
              </FlexRow>
            </DarkBackground>
          </Grid>
        </Grid>
      </div>
      <HorizontalSpacer height={6} />
    </Layout>
  );
};

export default Contact;
