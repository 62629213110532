import React from "react"
import styled, { css } from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { HorizontalSpacer, FlexColumn, CompanyCard, Slider } from "./"
import { systemPalette, appMaxWidth } from "../utils/constants"
import SKAILogo from "../images/skaiicon.png"
import FourAdLogo from "../images/4adlogo.jpg"
import BannerOne from "../images/banners/one.jpg"
import BannerTwo from "../images/banners/two.jpg"
import BannerThree from "../images/banners/three.jpg"
import BannerFour from "../images/banners/four.jpg"
import BannerFive from "../images/banners/five.jpg"
import BannerSix from "../images/banners/six.jpg"
import BannerSeven from "../images/banners/seven.jpg"


const HeroContainer = styled.div`
    /* background-color: ${systemPalette.primary.main}; */
    background-size: cover !important;
    background-position: center center !important;
    height: 100vh;
    width: 100%;
    @media (max-width: 960px) {
        min-height: 50vh;
        height: auto;
    }
`

const HeroTitle = styled(Typography)`
    && {
        font-size: 4rem;
        padding: 0;
        font-weight: 600;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        text-align: center;
        color: #fff;
        @media (max-width: 1368px) {
          padding: 0 1.2rem;
          font-size: 3rem;
        }
        @media (max-width: 1024px) {
          padding: 0 1.2rem;
          font-size: 2.5rem;
        }
        @media (max-width: 768px) {
          padding: 0 1.2rem;
          font-size: 1.6rem;
        }
    }
`
const HeroSubtitle = styled(Typography)`
    && {
        font-size: 1.5rem;
        padding: 0;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        text-align: center;
        color: #fff;
        line-height: 1.3;
        @media (max-width: 1368px) {
          font-size: 1rem;
          padding: 0 1.2rem;
        }
    }
`
const SloganContainer = styled.div`
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`

const Hero = ({ isDesktop }) => {
  const banners = [BannerOne, BannerTwo, BannerThree, BannerFour, BannerFive, BannerSix, BannerSeven]
  return <>
    <div style={{ position: "relative" }}>
      <Slider slidesToShow={1} autoplay infinite speed={3000} noArrows>
        {banners.map((data, index) => (
          <div key={index.toString()}>
            <HeroContainer isDesktop={isDesktop} style={{ background: `url(${data})` }} />
          </div>
        ))}
      </Slider>
      <SloganContainer>
        <FlexColumn just style={{ height: isDesktop ? "100vh" : "50vh" }} justifyContentCenter>
          <HeroTitle isDesktop={isDesktop} variant="h1">We connect the world through design.{<br />}Imagine it, we build it.</HeroTitle>
          <HorizontalSpacer />
          <HeroSubtitle isDesktop={isDesktop} variant="h1">Architecture with the environment and people in mind to create better lives through design</HeroSubtitle>
        </FlexColumn>
      </SloganContainer>
    </div>
    <div style={{ maxWidth: appMaxWidth, margin: "auto", transform: isDesktop ? "translateY(-50%)" : "none", padding: isDesktop ? "1rem" : "2rem" }}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CompanyCard
            isDesktop={isDesktop}
            imgUrl={SKAILogo}
            description="We will build your visions. We transform your ideas into reality.
                        Think of what you want to achieve. Think SKAI. Consider it done.
                        SKAI is the limit."
            url="/about/skai"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CompanyCard
            isDesktop={isDesktop}
            imgUrl={FourAdLogo}
            description="We create architecture, landscapes, interiors and brand design. We transform ordinary to
                        something that is extraordinary. We make design breathe and give life into it. We archi-
                        nected.”"
            url="/about/4ad"
          />
        </Grid>
      </Grid>
    </div>
    {!isDesktop && <HorizontalSpacer />}
  </>
}

export default Hero