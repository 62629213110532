import React from "react"
import styled from "styled-components"
import { Tabs, Tab } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { HorizontalSpacer } from "./"
import { systemPalette, appMaxWidth } from "../utils/constants"
import BannerImage from "../images/about.jpg"

const Banner = styled.div`
    background-size: cover !important;
    background-position-x: 50% !important;
    height: 540px;
    @media (max-width: 960px) {
        height: 220px;
    }
`

const Container = styled.div`
    background-color: ${systemPalette.gray.light};
`

const AboutNav = ({ value, imageUrl }) => {
    let history = useHistory();

    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 0:
                history.push("/about/skai")
                break;
            case 1:
                history.push("/about/4ad")
                break;
            case 2:
                history.push("/about/team")
                break;
            default:
                history.push("/about/skai")
        }
    };

    return <>
        <Banner
            style={
                imageUrl ? {
                    background: `url(${imageUrl})`,
                    backgroundPositionY: "15%"
                } :
                    {
                        background: `url(${BannerImage})`,
                        backgroundPositionY: "36%"
                    }
            }
        />
        <Container>
            <div style={{ maxWidth: appMaxWidth, margin: "auto", padding: "0 1rem" }}>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="SKAI" />
                    <Tab label="4:AD" />
                    <Tab label="Team" />
                </Tabs>
            </div>
        </Container>
        <HorizontalSpacer height={3} />
    </>
}

export default AboutNav