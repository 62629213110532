import React, { useEffect } from "react"
import styled from "styled-components"
import { Breadcrumbs, Typography, Button } from "@material-ui/core"
import { Link, useParams, useHistory } from "react-router-dom"
import { useQuery } from "@apollo/react-hooks"
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Layout from "../../components/layout"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { HorizontalSpacer, PageHeading, AboutHeader, Gallery, Slider, ProductCard, EditorViewer } from "../../components"
import { appMaxWidth, systemPalette } from "../../utils/constants"
import { GET_PRODUCT } from "../../api/products"
import { SkeletonTypography } from "../../components/skeleton"
import { getRandomInt } from "../../functions/"

const CarouselItem = styled.div`
  /* padding: 0 0.2rem; */
`;

const FeaturedContainer = styled.div`
  padding: 6rem 0;
  background-color: ${systemPalette.gray.light};
  @media (max-width: 960px) {
    padding: 3rem 0 1rem 0;
  }
`

const Project = () => {
    let { id } = useParams();
    let history = useHistory();
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const { loading, data } = useQuery(GET_PRODUCT, {
        variables: {
            _id: id,
        },
        onError: () => {
            history.push("/")
        }
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Layout>
            <HorizontalSpacer height={isDesktop ? 4 : 3} />
            <div style={{ maxWidth: appMaxWidth, margin: "auto", padding: "0 1rem" }}>
                {!loading && data?.getProduct && <>
                    {isDesktop ? <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/projects">Projects</Link>
                        <Link
                            to={`/projects/${
                                data.getProduct.categories[data.getProduct.categories.length - 1]
                                    ._id
                                }`}
                            state={{
                                categoryName:
                                    data.getProduct.categories[
                                        data.getProduct.categories.length - 1
                                    ].name,
                            }}
                        >
                            {
                                data.getProduct.categories[data.getProduct.categories.length - 1]
                                    .name
                            }
                        </Link>
                        <Typography color="textPrimary">
                            {data.getProduct.productName}
                        </Typography>
                    </Breadcrumbs> : <Link to={`/projects/${
                        data.getProduct.categories[data.getProduct.categories.length - 1]
                            ._id
                        }`}>
                            <Button size="small" startIcon={<ArrowBackIcon />}>
                                Back to Projects
                            </Button>
                        </Link>}
                </>}
                <HorizontalSpacer />
                {data?.getProduct?.images.length > 0 && <Gallery loading={loading} images={data?.getProduct?.images} />}
                <HorizontalSpacer />
                <AboutHeader loading={loading} title={data?.getProduct?.productName} />
                {loading && <>
                    <HorizontalSpacer height={1} />
                    {Array.from(new Array(10)).map((data, index) => (
                        <div key={index.toString()}>
                            <SkeletonTypography height="1rem" width={`${getRandomInt(40, 100)}%`} />
                            <HorizontalSpacer small />
                        </div>
                    ))} </>}
                {!loading && <EditorViewer description={data?.getProduct?.productDescription} />}
            </div>
            <HorizontalSpacer height={1} />
            {!loading && data?.getProduct?.relatedProducts?.length > 0 && <FeaturedContainer>
                <div
                    style={{ maxWidth: appMaxWidth, margin: isDesktop ? "auto" : "0 1rem", padding: "0 1rem  " }}
                >
                    <PageHeading title="Related Projects" />
                    <HorizontalSpacer height="3" />
                    <Slider isDesktop={isDesktop}>
                        {data?.getProduct?.relatedProducts?.map((data, index) => {
                            return (
                                <CarouselItem key={index.toString()}>
                                    <Link to={`/${data._id}`}>
                                        <div
                                            onClick={() => {
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            <ProductCard
                                                isTransparent
                                                title={data?.productName}
                                                category={data?.categories[0].name}
                                                description={data?.productDescription}
                                                imgUrl={data?.images[0]}
                                            /></div>
                                    </Link>
                                </CarouselItem>
                            );
                        })}
                    </Slider>
                </div>
                <HorizontalSpacer height={1} />
            </FeaturedContainer>}
        </Layout>
    )
}

export default Project
