import React from "react"
import styled, { css } from "styled-components"
import { useHistory } from "react-router-dom"
import { Typography, Grid } from "@material-ui/core"
import { HorizontalSpacer, FlexColumn, PageHeading, Button } from "./"
import { systemPalette, appMaxWidth } from "../utils/constants"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PeopleBehindImage from "../images/people-behind.jpg"

const HeroContainer = styled.div`
    background-color: ${systemPalette.primary.main};
    padding: 4rem 0;
    ${(props) =>
        props.isDesktop &&
        css`
      height: 100vh;
      padding: 0;
    `}
`

const Container = styled.div`
    ${(props) =>
        props.isDesktop &&
        css`
      height: 100vh;
    `}
    max-width: ${appMaxWidth};
    margin: auto;
    padding: 0 1rem;
`

const ImageViewer = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover !important;
    padding: 0;
    margin-top: -8px;
`

const Team = ({ isDesktop }) => {
    let history = useHistory();
    return <>
        <HeroContainer isDesktop={isDesktop}>
            <Container isDesktop={isDesktop}>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FlexColumn justifyContentCenter style={{ height: isDesktop ? "100vh" : "auto" }}>
                            <PageHeading title="The People Behind" isDarkMode centered={!isDesktop} />
                            <HorizontalSpacer height={3} />
                            <Typography variant={isDesktop ? "body1" : "body2"} style={{ color: "#fff" }}>
                                The designamic team always manifests unshakable optimism to move beyond the projected purposeful change where something didn’t exist before. They will be trend-setters who will always be imagining the future that is timeless.
                            </Typography>
                            <HorizontalSpacer />
                            <Typography variant={isDesktop ? "body1" : "body2"} style={{ color: "#fff" }}>
                                It all started with a line. A straight line. It curved into a vision. A vision to create design. An extraordinary design.
                            </Typography>
                            <HorizontalSpacer large />
                            <div>
                                <Button onClick={() => history.push("/about/team")} light endIcon={<ArrowRightAltIcon />}>Read More</Button>
                            </div>
                            {!isDesktop && <HorizontalSpacer large />}
                        </FlexColumn>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {isDesktop ? <ImageViewer style={{ background: `url(${PeopleBehindImage})` }} /> : <img src={PeopleBehindImage} alt="team" />}
                    </Grid>
                </Grid>
            </Container>
        </HeroContainer>
    </>
}

export default Team