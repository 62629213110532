import React from "react";
import { Search } from "../"
import Layout from "./layout";

const SearchSiteFilter = (props) => {
  const { onChange, value, onSubmit, isMobile } = props
  return (
    <Layout title="Search" isMobile={isMobile}>
      <Search onChange={onChange} value={value} onSubmit={onSubmit} />
    </Layout>
  )
};

export default SearchSiteFilter;
