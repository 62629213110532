import ArchitectureImage from "../images/architecture.jpg"
import EngineeringImage from "../images/engineering.jpg"
import InteriorDesignImage from "../images/interiordesign.jpg"
import ProjectManagementImage from "../images/projectmanagement.jpg"
import GeneralConstructionImage from "../images/construction.jpg"
import TestiOneImage from "../images/testi-one.jpg"
import TestiTwoImage from "../images/testi-two.jpg"

export const appSettings = {
  name: "SKAI",
  facebookUrl: "https://www.facebook.com/everyshopph",
  contactNo: "831-1416",
  palette: {
    primary: {
      main: "#FFA931",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#B9AC92",
      contrastText: "#ffffff",
    },
  },
};

export const services = [
  {
    imageUrl: ArchitectureImage,
    title: "Architecture Design",
    description: "We create better lives by transforming our client’s concepts into carefully planned design elements that unifies them into a coherent and functional whole, achieving objectives, service-oriented architecture within a given budget. We practice investigative design and focus on building your visions."
  },
  {
    imageUrl: InteriorDesignImage,
    title: "Interior Design",
    description: "We create interior design that you can live with forever. Every space is functional and pleasing to the eye. Every corner will tell its unique design story. We are challenged by new concepts and are driven by extraordinary ideas. We are updated with the latest in environment friendly materials that are both economical and stylish. We put together every component into a unified design experience. We put your ideas into focus."
  },
  {
    imageUrl: ProjectManagementImage,
    title: "Project Management",
    description: "We provide a team of experts to ensure that every project is built according to plan, specifications, timeframe, budget and success criteria. We manage projects to achieve development goals within the given constraints. Every project is a solid commitment."
  },
  {
    imageUrl: EngineeringImage,
    title: "Engineering",
    description: "We have a strong and experienced engineering team that is the backbone of our design. We carefully incorporate the basic needs of a structure from a safe skeletal framing system, provision of water and electricity, mechanical air flow, proper sewage disposal and waste management, to the intricate more advanced security system, fire protection and technologically intelligent advanced requirements. We also provide green practices to save on electricity and natural means of water collection. We are experienced in land use planning and development that allows the study of a given lot to provide the most beneficial use of the property."
  },
  {
    imageUrl: GeneralConstructionImage,
    title: "General Construction",
    description: "We apply our years of knowledge in providing a well supervised and safe construction environment. Our years of experience to utilize skills, tools and techniques is continuously being updated and enhanced by keeping up to date with the latest in construction technology. We have a skilled team of experts to execute the design and your imagination into a tangible structure. Our construction team is trained to meet project requirements according to agreed parameters on time and within the agreed budget. We will build your visions."
  }
]

export const testimonials = [
  {
    _id: "125",
    name: "Jonathan & Cherry Jeffrey",
    description: "This is the second dwelling that Ar. Sevilla and his team at SEVILLA KEYSTONE ARCHITECTS have designed and built for us. The completed house in which we have been living for almost one year is delightful; it has exceeded our requirements and expectations. Ar. Sevilla carefully designed a property that considered our plot, budget, the environment, our basic requirements and the style and features of property desired. An optimised layout combined with a strong structure was the starting point that led onto careful specification of external and internal features and finishes. The quality of the construction is admirable. Quality materials and good workmanship combined with competent procurement and subcontracting have resulted in a high quality, durable dwelling that has been admired by many visitors over the past few months. The internal and external architectural finishes are many and varied including external and internal stone cladding, extensive window area, and floor finishes in tiling and laminates. Ar. Sevilla has continued to support and assist us this year with some additional features and works. We appreciate that continued support since handover of the house to us.",
    imageUrl: TestiOneImage
  },
  {
    _id: "124",
    name: "",
    description: "We'd like to say a big thank you to you and the rest of the team. Your help and support throughout the planning and building process has been very professional and truly wonderful. We felt very happy and satisfied with the high quality products. Since my husband is Japanese, we wanted to have a Japanese-inspired home and SKAI have been very helpful with that and exceeded our expectations. We absolutely love our home and have received many compliments on it. It was an awesome experience and we would highly recommend SKAI if you are looking to build a house. Once again, thank you for building our dream home. Godbless and more power to your company!",
    imageUrl: TestiTwoImage
  },
  {
    _id: "123",
    name: "",
    description: "A country home is what we wanted for our lot in Tagaytay, and he was able to materialize the vision we wanted for our house. We wanted a gazebo, a porch with swing, a fireplace and three rooms and he incorporated everything even with limited space. He was patient in listening to our ideas and presented the pros and cons of these suggestions. We didn't have time to choose the materials and so we entrusted it to him and wow! ang ganda ng pagkagawa. Very satisfied kami lalo sa ilaw, which we feel is his specialty. He worked with our timeline and budget. While he helped us save costs, he made sure na de kalidad ang materials at class pa rin ang bahay. And even the landscaping kahit hindi na kasama sa scope of work nya, he had recommendations para lalo kami masiyahan. Lahat ng dinala namin sa bahay ay nagagandahan talaga. Thank you for your work! ATE SEVILLA",
    imageUrl: ""
  },
]