import React from "react"
import styled, { css } from "styled-components"
import { Typography } from "@material-ui/core"
import { HorizontalSpacer } from "./"
import { systemPalette } from "../utils/constants"

const ProductContainer = styled.div`
  /* background-color: #fff; */
  border-radius: 1.2rem;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    opacity: 0.5;
  }
  ${(props) =>
    props.isTransparent &&
    css`
      background-color: transparent;
    `
  }  
`

const ProductImageContainer = styled.div`
  padding: 0.5rem;
`

const ProductBodyContainer = styled.div`
  padding: 0.5rem 1.2rem 2rem 1.2rem;
`

const ProductImage = styled.div`
  height: 240px;
  width: 100%;
  background-size: cover !important;
  background-color: #373738;
  background-position: center !important;
  border-radius: 0.8rem;

  @media (max-width: 960px) {
    height: 220px;
  }

  @media (max-width: 460px) {
    height: 90px;
  }
`

const ProductTitle = styled(Typography)`
  && {
    font-weight: 500;
    @media (max-width: 960px) {
      font-size: 0.8rem;
    }
  }
`

const ProductCategory = styled(Typography)`
  && {
    font-size: 0.6rem;
    color: ${systemPalette.secondary.main};
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    background-color: ${systemPalette.secondary.light};
  }
`

const formatImgUrl = (imgUrl) => {
  if (/\s/.test(imgUrl)) {
    return encodeURI(imgUrl)
  }
  return imgUrl
}


const ProductCard = ({ title, description, category, imgUrl, isTransparent }) => {
  return (
    <ProductContainer isTransparent={isTransparent}>
      <ProductImageContainer>
        <ProductImage style={{ backgroundImage: `url(${formatImgUrl(imgUrl)})` }} />
      </ProductImageContainer>
      <ProductBodyContainer>
        <ProductCategory variant="body2" component="span" color="textSecondary">
          {category}
        </ProductCategory>
        <HorizontalSpacer small />
        <ProductTitle variant="body1">{title}</ProductTitle>
        {/* <HorizontalSpacer height={1} />
        <Typography variant="body2">{description}</Typography> */}
      </ProductBodyContainer>
    </ProductContainer>
  )
}

export default ProductCard
