import { ApolloClient, HttpLink, from } from "@apollo/client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "@apollo/link-error";
import { API_URL, APP_ID } from "./constants";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const appMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    headers = {
      ...headers,
      appId: APP_ID,
    };

    return {
      headers
    };
  });

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: API_URL,
  fetch
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, appMiddleware, httpLink])
});

export default client;
