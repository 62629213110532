import React, { useState } from "react"
import { Avatar, Typography } from "@material-ui/core"
import { FlexRow, VerticalSpacer, FlexColumn, HorizontalSpacer } from "./"
import styled from "styled-components"

const Paper = styled.div`
    padding: 2rem 4rem;
    background-color: #fff;
    box-shadow: 0 7px 21px 0 rgba(0,0,0,.1);
    border-radius: 0.5rem;
    width: auto;
    margin: auto;
    display: inline-block;
    max-width: 960px;
    margin: 2rem;
    @media (max-width: 960px) {
        padding: 1.5rem;
        margin: 1rem;
    }
`

const ReadMoreLessBtn = styled(Typography)`
    && {
        color: #61A3F9;
        cursor: pointer;
        transition: 0.5s;
        font-weight: 500;
        &:hover {
            opacity: 0.5;
        }
    }
`

const Testimonial = ({ isDesktop, data }) => {
    const [open, setOpen] = useState(false);
    const maxChar = isDesktop ? 500 : 140;
    const description = data?.description
    return <Paper>
        <FlexRow style={{ flexDirection: isDesktop ? "row" : "column" }} alignItemsCenter>
            <Avatar style={{ height: isDesktop ? "160px" : "90px", width: isDesktop ? "160px" : "90px", margin: isDesktop ? 0 : "auto" }} src={data?.imageUrl} />
            {isDesktop ? <VerticalSpacer large /> : <HorizontalSpacer />}
            <FlexColumn style={{ textAlign: "left" }}>
                <Typography variant="body2" color="textSecondary">
                    {description.slice(0, maxChar)}
                    {description.length > maxChar && !open &&
                        <>
                            {"..."}
                            <ReadMoreLessBtn
                                variant="body2"
                                component="span"
                                onClick={() => setOpen(!open)}
                            >
                                {" "}read more
                    </ReadMoreLessBtn >
                        </>
                    }
                    {open && description.length > maxChar && description.slice(maxChar, description.length)}
                    {description.length > maxChar && open &&
                        <>
                            <ReadMoreLessBtn
                                variant="body2"
                                component="span"
                                onClick={() => setOpen(!open)}
                            >
                                {" "}read less
                    </ReadMoreLessBtn >
                        </>
                    }
                </Typography>
                <HorizontalSpacer />
                <Typography variant="body2">{data?.name}</Typography>
            </FlexColumn>
        </FlexRow>
    </Paper>
}

export default Testimonial