import styled, { css } from "styled-components";
import FeatherIcon from "feather-icons-react";

const StyledFeatherIcon = styled(FeatherIcon)`
  && {
    height: 1.2rem;
    width: 1.2rem;
    ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  }
`;

export default StyledFeatherIcon;
