import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { InputBase } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    backgroundColor: "transparent",
    borderRadius: "2px",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    height: "40px",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "0.2rem"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Search = ({ value, onChange, onSubmit }) => {
  const classes = useStyles()

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search Projects"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onKeyDown={onSubmit}
        onChange={onChange}
        inputProps={{ "aria-label": "search" }}
        fullWidth
        value={value}
      />
    </div>
  )
}

export default Search;
