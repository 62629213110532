import React from "react";
import styled from "styled-components";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevroLeftIcon from "@material-ui/icons/ChevronLeft";
import Slider from "react-slick";

const StyledNextArrow = styled(ChevronRightIcon)`
  && {
    right: -25px;
  }
`;

const StyledPrevArrow = styled(ChevroLeftIcon)`
  && {
    left: -25px;
  }
`;

const CustomNextArrow = (props) => {
  const { style, onClick, className } = props;
  return (
    <StyledNextArrow
      className={className}
      onClick={onClick}
      style={{ ...style }}
    />
  );
};

const CustomPrevArrow = (props) => {
  const { style, onClick, className } = props;
  return (
    <StyledPrevArrow
      className={className}
      onClick={onClick}
      style={{ ...style }}
    />
  );
};

const StyledSlider = ({ children, isDesktop, slidesToShow, dots, autoplay, noArrows, infinite, speed }) => {
  const settings = {
    dots,
    infinite,
    slidesToShow: slidesToShow ? slidesToShow : (isDesktop ? 4 : 2),
    slidesToScroll: 1,
    autoplay,
    speed: speed ? speed : 500,
    autoplaySpeed: 8000,
    arrows: !noArrows,

    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  return (
    <>
      <Slider {...settings}>{children}</Slider>
    </>
  );
};

export default StyledSlider;
