import styled, { css } from "styled-components"
import { Button } from "@material-ui/core"

const StyledButton = styled(Button)`
    && {
        border-radius: 2rem;
        padding: 0.5rem 2rem;
        ${(props) =>
    props.light &&
    css`
      background-color: #fff;
      color: #373738;
    `}
    }
`

export default StyledButton