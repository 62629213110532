import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Typography } from "@material-ui/core"
import { HorizontalSpacer } from "./"

const CardContainer = styled.div`
    text-align: center;
    padding: 2rem;
    box-shadow: 0 7px 21px 0 rgba(0,0,0,.1);
    border-radius: 0.5rem;
    background-color: #bfbfbf;
    @media (max-width: 960px) {
        padding: 1rem;
    }
`

const CompanyCard = ({ imgUrl, description, url, isDesktop }) => {
    return <CardContainer isDesktop={isDesktop}>
        <img src={imgUrl} alt="icon" style={{ maxHeight: isDesktop ? "120px" : "70px" }} />
        <HorizontalSpacer small={!isDesktop} />
        <Typography variant={isDesktop ? "body1" : "body2"} style={{ fontWeight: 100 }}>{description}</Typography>
        <HorizontalSpacer small={!isDesktop} />
        <Link to={url}>
            <Typography variant={isDesktop ? "body1" : "body2"} style={{ color: "#ffe975", fontWeight: 400 }}>
                read more
            </Typography>
        </Link>
    </CardContainer>
}

export default CompanyCard